/********** header background color and font color **********/
.ant-table .ant-table-thead > tr > th {
  // background: #f0f0f0;
  color: #696969;
  font-weight: 700;
  font-size: 14px;
  // background: transparent;
  // color: @secondary-text;
}
/********** border color & font color **********/
/* Normal borders (Discreet borders in commonux) */
.ant-table-thead > tr > th,
.ant-table-tbody > tr:last-child > td {
  border-bottom-color: @grey-30;
}
.ant-table-tbody > tr > td {
  border-bottom-color: @grey-10;
}
/* Dark borders (Normal border in commonux) */
.ant-table-wrapper.dark-border {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr:last-child > td {
      border-bottom-color: @grey-90;
    }
    .ant-table-tbody > tr > td {
      border-bottom-color: @grey-30;
    }
}
// /* Discreet borders */
// .ant-table-wrapper.discreet-border {
//   .ant-table-thead > tr > th,
//   .ant-table-tbody > tr:last-child > td {
//     border-bottom-color: @grey-30;
//   }
//   .ant-table-tbody > tr > td {
//     border-bottom-color: @grey-10;
//   }
// }
/* No borders */
.ant-table-wrapper.no-border {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr:last-child > td {
      border-bottom-color: @grey-30;
    }
    .ant-table-tbody > tr > td {
      border-bottom-color: transparent;
    }
  }

/********** row height **********/
/* Small */
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small tfoot > tr > td,
.ant-table.ant-table-small tfoot > tr > th {
  padding: @space-half @space-2x;
}
/* Medium */
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle tfoot > tr > td,
.ant-table.ant-table-middle tfoot > tr > th {
  padding: @space @space-2x;
}
/* Large */
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  padding: (@space-half*3) @space-2x;
}

/********** link button in table **********/
.ant-table-cell .ant-btn-link {
  padding: 0;
  height: auto;
  border: none;
}
