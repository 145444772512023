/* ABB voice */
@font-face {
  font-family: 'ABBVoice-light';
  src: url('../assets/fonts/ABBvoice_W_Lt.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_Lt.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Lt.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Lt.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Lt.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: ABBVoice;
  src: url('../assets/fonts/ABBvoice_W_Rg.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_Rg.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Rg.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Rg.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Rg.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ABBVoice-medium';
  src: url('../assets/fonts/ABBvoice_W_Md.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_Md.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Md.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Md.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Md.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'ABBVoice-bold';
  src: url('../assets/fonts/ABBvoice_W_Bd.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_Bd.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Bd.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Bd.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Bd.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: ABBVoice;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-family: 'ABBVoice-bold';
}
p {
  font-family: 'ABBVoice-light';
  font-weight: 300;
}
