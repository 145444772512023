.content-header {
  display: flex;
  margin-bottom: 8px;
  border-bottom: 2px solid #868686;
}
.content-header > .content-header-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-family: "ABBVoice-bold";
  font-weight: bold;
  line-height: 1;
}
