/* layout */
.layout-default .app-header-container,
.layout-default .app-body-container,
.layout-default .app-footer-container {
  text-align: center;
}
.layout-default .app-header-wrapper,
.layout-default .app-body-wrapper,
.layout-default .app-footer-wrapper {
  margin: 0 auto;
  width: 88%;
  min-width: 1200px;
  text-align: left;
}
.layout-default .app-header-container {
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.layout-default .app-header-wrapper {
  background: inherit;
}
.layout-default .app-header-wrapper > .abb-bar {
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-family: 'ABBVoice-bold';
  font-size: 25px;
  font-weight: 700;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23ff000f' viewBox='-1 -1 88.2 35'><path d='M47 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H47V33z'/><rect width='10' height='16' x='36' y='17'/><path d='M57.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H47v16h10.3z'/><rect width='10' height='16' x='36'/><path d='M74 33h2c6-.3 10.2-5 10.2-10.6 0-1.9-.4-3.8-1.3-5.3H74V33z'/><rect width='10' height='16' x='63' y='17'/><path d='M84.3 16c-1-1.4-2.4-2.5-3.9-3.3 1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7H74v16h10.3z'/><rect width='10' height='16' x='63'/><polygon points='5.7,17 0,33 8.3,33 10.7,26 16,26 16,17'/><polygon points='16,0 11.7,0 6,16 16,16'/><polygon points='17,26 22.3,26 24.7,33 33,33 27.3,17 17,17'/><polygon points='27,16 21.3,0 17,0 17,16'/></svg>") no-repeat 16px 50%;
  background-size: 100px 60px;
}
.layout-default .app-header-wrapper > .app-header-bar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.layout-default .app-header-wrapper > .app-header-bar .app-header-bar-controls {
  display: flex;
  align-items: stretch;
}
.layout-default .app-header-wrapper > .app-header-bar .app-header-bar-controls > .control-item {
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: #696969;
  width: max-content;
}
.layout-default .app-header-wrapper > .app-header-bar .app-header-bar-controls > .control-item:hover {
  background-color: #ebebeb;
  color: #1f1f1f;
  cursor: pointer;
}
.layout-default .app-header-wrapper > .app-header-bar .app-header-bar-controls > .control-item .anticon {
  font-size: 24px;
  margin-right: 4px;
}
.layout-default .app-header-wrapper > .app-header-bar .app-header-bar-controls > .control-item > .lang {
  display: flex;
  align-items: center;
}
.layout-default .app-header-wrapper > .app-header-bar .app-header-bar-controls > .ant-divider-vertical {
  margin: 0;
  align-self: center;
  height: 32px;
}
.layout-default .app-body-wrapper {
  margin-top: 16px;
  padding: 28px 16px 32px 16px;
  min-height: 66vh;
  background-color: white;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
}
.layout-default .app-body-wrapper > .wrapper-no-padding {
  display: flex;
  margin: -28px -16px -32px -16px;
  min-height: inherit;
}
.layout-default .app-body-wrapper > .wrapper-no-padding.column {
  flex-direction: column;
}
.layout-default .app-footer-wrapper {
  padding: 16px 0;
  text-align: center;
}
.layout-default .ant-menu-horizontal {
  line-height: 35px;
}
.layout-default .app-header-container {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}
.layout-default .app-body-container {
  margin-top: 100px;
}
